import {ApiService} from '@/services/ApiService'

const URL = '/api/template'

class TemplateService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
}

export default new TemplateService()