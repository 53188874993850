<template>
    <Overview 
        :moduleName="$tc('navigation.' + this.$route.name, 1)"
        :moduleNamePlural="$tc('navigation.' + this.$route.name, 2)"
        :sort="'name'"
        :sortOrder="1"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :showCopy="false"
        ref="overview"
    >
        <template #columns>
            <Column field="name" :header="$t('overview.name')" :sortable="true" style="width: 25%"></Column>
            <Column :header="$t('overview.contents')" style="width: 55%">
                <template #body="slotProps">
                    {{
                        //eslint-disable-next-line
                        (slotProps.data.contents.length <= 50 ? slotProps.data.contents : (slotProps.data.contents.substr(0, 50) + '...'))
                    }}
                </template>
            </Column>
            <Column field="is_appendage" :header="$t('overview.type')" :sortable="true" style="width: 15%">
                <template #body="slotProps">{{ slotProps.data.is_appendage ? $t('navigation.attachments') : $t('navigation.templates') }}</template>
            </Column>
        </template>

        <template #form>
            <TemplateForm ref="ItemForm" :itemData="item" />
        </template>

        <template #modal-footer>
            <!--<button id="nextbutton" type="button" class="btn btn-success" @click.prevent="next">&gt;&ensp;Volgende<span id="nextStep">2</span></button>-->
        </template>
    </Overview>
  <div class="footer-buttons mb-2">
    <button type="button" class="btn action-button" data-bs-toggle="modal" data-bs-target="#formModal" @click="$refs.overview.addItem()">{{ $t('form.add', [$tc('navigation.' + this.$route.name, 1)]) }}</button>
  </div>
</template>

<script>
import store from '@/store/user'
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import templateService from '@/services/TemplateService';
import TemplateForm from '@/components/forms/TemplateForm.vue';

export default {
    name: 'Templates',
    components: {
        Overview,
        Column,
        TemplateForm
    },
    data() {
        return {
            item: {},
            itemFormRef: null,
            itemService: templateService,
        }
    },
    methods: {
        next() {
            let tabs = document.querySelectorAll("#formModal ul.nav-tabs .nav-link");
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
        if(!store.getters.getIsHR){
            this.$router.push({
                name: "Home"
            });
        }
    }
}
</script>